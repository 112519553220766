



























import Vue from "vue";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      self: this,
      userManager,
      item: null,
      currentTab: "",
      tabs: {
        print_barcode: {
          label: "Print Barcode",
        },
        print_main: {
          label: "Print",
        },
        print_design: {
          label: "Print Design",
        },
        confirm_print_main: {
          label: "Confirm Printed",
        },
        set_quality_check_failed: {
          label: "Quality Check",
        },
        package: {
          label: "Package",
        },
      },
    };
  },
});
